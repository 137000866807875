<template>
	<div id="app-confirmation">
		<app-stepper :step-number="5"></app-stepper>

		<div class="row flex-column align-items-center mainBox">
			<div class="col-12 mb-4 d-flex justify-content-center">
				<div class="dataIconKB"></div>
			</div>
			<h2 class="col-11 col-xl-8 mb-5 text-center text-format-1">
				¡Thank you, {{ $store.state.onboarding.nombreCliente }}! There is very little time left to
				finalize the process of signing up for your Transparent Account.
			</h2>
			<p class="m-0 text-format-2">
				You will shortly receive in your email the documents associated with the account.
				transparent:
			</p>
			<ul class="mt-3 text-format-2">
				<li>Pre-contractual product information.</li>
				<li>Master agreement for the provision of banking services.</li>
				<li>Contract acceptance document and particular conditions.</li>
			</ul>
			<p class="m-0 mt-md-5 mt-3 text-format-2">
				Please don't forget to sign them! Don't forget to sign them in order to enjoy all their
				benefits.
			</p>
			<p class="m-0 mt-4 mb-5 mb-sm-1 text-format-3">THANK YOU!</p>
			<p class="m-0 mt-md-5 mt-4 text-format-2">
				In the meantime, we invite you to download our app available at:
			</p>
			<div class="badges d-flex justify-content-center mt-4">
				<a class="badgeApple" href="#"></a>
				<a class="badgeGoogl" href="#"></a>
			</div>
		</div>
	</div>
</template>

<script>
import AppStepper from '@/components/app-stepper';

export default {
	name: 'app-confirmation',
	components: {
		AppStepper,
	},
};
</script>

<style lang="scss" scoped>
.mainBox {
	margin: 30px 0;
}
ul {
	margin: 0 60px;
}
.dataIconKB {
	height: 62px;
	width: 62px;
	background-image: url(~@/assets/images/end.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
.badges .badgeApple {
	margin-right: 10px;
	height: 50px;
	width: 170px;
	background-image: url(~@/assets/images/Apple.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
.badges .badgeGoogl {
	margin-left: 10px;
	height: 50px;
	width: 170px;
	background-image: url(~@/assets/images/Google.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
@media screen and (max-device-width: 480px) {
	p {
		text-align: center;
		display: inline-block;
		padding: 0 10px;
	}
}
</style>
